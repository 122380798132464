import React from 'react';

import { Wrapper, Main, Footer } from './styles';

import NavBarTopo from './../NavBarTopo';

import FooterText from './../FooterText';

const Page = ({children, title, subtitle}) => {
    return (
        <>
            <Wrapper>
                <header>
                    <NavBarTopo />
                </header>
                <Main>
                    {children}
                </Main>
                <Footer>
                    <FooterText />
                </Footer>
            </Wrapper>
        </>
    );
}

export default Page;
