import React from 'react';

import {
	Switch,
	Route,
    Redirect,
} from "react-router-dom";

import Page from './../components/Page';

import Home from './../pages/Home';
import GrupoSalaPlayList from './../pages/GrupoSalaPlaylist';

//import GrupoSalaSalas from './../pages/GrupoSalaSalas';
import GrupoSalaSalaView from './../pages/GrupoSalaSalaView';

import SalaView from './../pages/SalaView';

const AppRoutes = () => {
    return (
        <Page>
            <Switch>
                <Route path="/" exact component={Home} />
                {
                //<Route path="/grupo/:grupo_sala_id/salas" exact component={GrupoSalaSalas}/>
                //<Route path="/grupo/:grupo_sala_id/salas/list" exact component={GrupoSalaPlayList}/>
                }
                <Route path="/grupo/:grupo_sala_id/salas" exact component={GrupoSalaPlayList}/>
                <Route path="/grupo/:grupo_sala_id/salas/:sala_id" component={GrupoSalaSalaView}/>

                <Route path="/sala/:sala_id" component={SalaView} />

                <Redirect to="/" />
            </Switch>
        </Page>
    );
}

export default AppRoutes;
