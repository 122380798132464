import axios from 'axios';
import { api_url } from './../config';

const api = axios.create({
    baseURL: api_url,
});

api.interceptors.response.use(function (response) {
    // Do something with response data
    return response;
}, function (error) {
    // Do something with response error
    console.log(error);
    if(error.response && error.response.status === 401){
        sessionStorage.clear();
        window.location.href = '/';
    }else{
        window.location.href = '/';
    }

    return Promise.reject(error);
});

export default api;
