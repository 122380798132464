import React, { createContext, useState, useEffect, useContext } from 'react';
import * as auth from '../services/auth';
import api from './../services/api';

import decode from 'jwt-decode';

const AuthContext = createContext({
    loadingAuth: true,
    signed: false,
    user: null,
    isAdmin: false,
    loginError: null,
    signInToken: () => {},
    signInUser: () => {},
    signOut: () => {},
    refreshToken: () => {}
});

const AuthProvider = ({children}) => {

    const [user, setUser] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [loadingAuth, setLoadingAuth] = useState(true);
    const [loginError, setLoginError] = useState(null);

    const a = 1; //para resolver o bug de reload infinito =/

    useEffect(() => {

        (async () => {
        
            //carrega os dados da storage
            var storagedUser = JSON.parse(sessionStorage.getItem("@PUri:user"));
            var storagedToken = sessionStorage.getItem("@PUri:token");

            if(!storagedUser || !storagedToken){
                //setLoginError('Sua sessão expirou!');
                setLoadingAuth(false);
                return false;
            }

            const decodedToken = decode(storagedToken);

            if(!decodedToken){
                setLoginError('Sua sessão expirou!');
                signOut();
                setLoadingAuth(false);
                return false;
            }

            //verifica a expiração do token
            if(decodedToken.exp <= Math.floor(new Date() / 1000)){
                api.defaults.headers.Authorization = `Bearer ${storagedToken}`;
                
                const {data} = await auth.refreshToken();

                //se não retornar outro token, expira a sessão para pedir o login novamente
                if(!data.token){
                    setLoginError('Sua sessão expirou!');
                    storagedUser = null;
                    storagedToken = null;

                    signOut();
                    setLoadingAuth(false);
                    return false;
                    
                }else{
                    sessionStorage.setItem("@PUri:token", data.token);
                    storagedToken = data.token;
                }
            }

            //se existir seta o usuário
            if (storagedUser && storagedToken) {
                setUser(storagedUser);
                api.defaults.headers.Authorization = `Bearer ${storagedToken}`;

                verifyIsUserAdmin();
            }

            setLoadingAuth(false);
        })();

    }, [a]);

    function signIn(response){
        const {data} = response;

        const {user} = data;
        const {token} = data;

        if(user && token){
            setUser(user);

            api.defaults.headers.Authorization = `Bearer ${token}`;

            sessionStorage.setItem("@PUri:user", JSON.stringify(user));
            sessionStorage.setItem("@PUri:token", token);

            verifyIsUserAdmin();

            setLoginError(null);
        }else{
            setLoginError(data.message);
        }

        setLoadingAuth(false);
    }

    async function signInToken(token) {
        setLoadingAuth(true);
        const response = await auth.signInToken(token);
        signIn(response);
    }

    async function signInUser(email, codigo_acesso){
        setLoadingAuth(true);
        const response = await auth.signInUser(email, codigo_acesso);
        signIn(response);
    }

    async function signOut(){
        
        setLoadingAuth(true);
        
        const {data} = await api.post('/logout');

        if(data.logout) {
            sessionStorage.clear();
            setUser(null);
        }

        setLoadingAuth(false);
    }

    async function verifyIsUserAdmin(){
        //verifica se o usuário é administrador para poder visualizar as salas indepentende da data da mesma
        const {data} = await api.post('/is-user-admin');

        console.log(data);
        setIsAdmin(data.is_admin ?? false);
    }

    async function refreshToken(){
        const {data} = await auth.refreshToken();

        if(!data.token){
            return false;
        }

        sessionStorage.setItem("@PUri:token", data.token);
        api.defaults.headers.Authorization = `Bearer ${data.token}`;

        return true;
    }

    return (
        <AuthContext.Provider 
            value={{
                loadingAuth, 
                signed: !!user, 
                user,
                isAdmin,
                loginError,
                signInToken, 
                signInUser, 
                signOut,
                refreshToken
            }}>
            {children}
        </AuthContext.Provider>
    );
};

function useAuth() {
    const context = useContext(AuthContext);
    return context;
}

export {AuthProvider, useAuth};