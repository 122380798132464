import styled from 'styled-components';

import { Button } from 'reactstrap';

import theme from './../../styles/theme';

export const CustomButton = styled(Button)`
    color: ${theme.primary};
    border-color: ${theme.primary};

    transition: all 0.2s;

    &:hover {
        background-color: ${theme.secondary};
        border-color: ${theme.secondary};
        color: ${theme.textColor};
    }
`;
