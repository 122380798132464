import styled, {createGlobalStyle} from 'styled-components';

import theme from './theme';

export const GlobalStyle = createGlobalStyle`
    * {
        padding: 0;
        margin: 0;
    }

    html, body {
        position: relative;
        height: 100%;
        min-height: 100%;
    }
`;

export const PlayURIDark = styled.h1`
    font-weight: 900;
    text-transform: uppercase;
    text-shadow: 
        2px 0 0 ${theme.primary}, 
        -2px 0 0 ${theme.primary}, 
        0 2px 0 ${theme.primary}, 
        0 -2px 0 ${theme.primary}, 
        1px 1px ${theme.primary}, 
        -1px -1px 0 ${theme.primary}, 
        1px -1px 0 ${theme.primary}, 
        -1px 1px 0 ${theme.primary}
    ;
`;

export const PlayURIWhite = styled.h1`
    font-weight: 900;
    text-transform: uppercase;
    text-shadow: 
        2px 0 0 #fff, 
        -2px 0 0 #fff, 
        0 2px 0 #fff, 
        0 -2px 0 #fff, 
        1px 1px #fff, 
        -1px -1px 0 #fff, 
        1px -1px 0 #fff, 
        -1px 1px 0 #fff
    ;
`;

export default GlobalStyle;