import React, { useState, useEffect } from 'react';

import { 
	SContainer, 
	STituloContent, 
	STitulo,
	GrupoSalaItem, 
	GrupoSalaItemContent,
	GrupoSalaItemContentDetail
} from './styles';

import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import UBreadcrumb from './../../components/UBreadcrumb';
import Loading from './../../components/Loading';
import SalaItem from './../../components/SalaItem';

import api from '../../services/api';

import {periodFromDates, hourFromDate} from './../../services/utils';

import { 
	Row,
} from 'reactstrap';

function Home() {

	const [loadingPage, setLoadingPage] = useState(true);
	const [gruposSalas, setGruposSalas] = useState([]);
	const [salasSemGrupo, setSalasSemGrupo] = useState([]);

	async function loadGruposSalas(){
		const {data} = await api.get('/grupo/salas');
		console.log(data);
		setGruposSalas(data.grupossalas);
		setSalasSemGrupo(data.salasSemGrupo);
		setLoadingPage(false);
	}

	useEffect(() => {

		if(sessionStorage.getItem("@PUri:salaAtiva")){
			sessionStorage.removeItem("@PUri:salaAtiva");
		}

		loadGruposSalas();
	}, []);

	if(loadingPage)
		return <Loading text="Carregando salas..." />

	var nomeEvento = null;
	if(gruposSalas.length)
		nomeEvento = gruposSalas[0].evento_nome;
	else if (salasSemGrupo.length)
		nomeEvento = salasSemGrupo[0].evento_nome;

    return (
		<SContainer>
			<UBreadcrumb tituloEvento={nomeEvento} paths={[]} />
			<div>
				{(salasSemGrupo.length) 
					? (
					<>
						<STitulo>Salas do evento</STitulo>
						<Row className="mb-4">
							{salasSemGrupo.map((sala) => 
								<SalaItem key={sala.id} sala={sala} salaLink="/sala" />
							)}
						</Row>
					</>
					) : null
				}

				<STituloContent>
					<STitulo>Grupos de salas</STitulo>
				</STituloContent>

				{gruposSalas.map((grupoSala) => (
					<GrupoSalaItem key={grupoSala.id} to={`/grupo/${grupoSala.id}/salas`} title={`Ver salas de ${grupoSala.titulo}`}>
						<GrupoSalaItemContent>
							<div>
								{grupoSala.titulo}
								<div className="text-muted small">
									{`${periodFromDates(grupoSala.periodoInicial, grupoSala.periodoFinal)} | ${hourFromDate(grupoSala.periodoInicial)} - ${hourFromDate(grupoSala.periodoFinal)}`}
								</div>
							</div>
							<ArrowRightIcon />
						</GrupoSalaItemContent>
					</GrupoSalaItem>
				))}
			</div>
		</SContainer>
	);
}

export default Home;