import React, { useState, useEffect } from 'react';

import {Container} from 'reactstrap';
import api from './../../services/api';

import UBreadcrumb from './../../components/UBreadcrumb';
import SalaView from './../../components/SalaView';
import Loading from './../../components/Loading';


function GrupoSalaSalaView({match}) {

    const grupo_sala_id = match.params.grupo_sala_id;
    const sala_id = match.params.sala_id;

    const [loadingPage, setLoadingPage] = useState(true);
    const [grupoSala, setGrupoSala] = useState({});
    const [sala, setSala] = useState({});

    async function fetchSala(){
        const {data} = await api.get(`/grupo/${grupo_sala_id}/sala/${sala_id}`);
        setSala(data.sala);
        setGrupoSala(data.sala.grupoSala);
        setLoadingPage(false);
    }

    useEffect(() => {
        fetchSala();
    }, [grupo_sala_id, sala_id]);

    if(loadingPage)
        return <Loading text="Carregando sala..."/>

    return (
        <Container>
            <UBreadcrumb 
                tituloEvento={grupoSala.evento_nome}
                paths={[
                    {title: 'Início', to: '/'},
                    {title: grupoSala.titulo, to: `/grupo/${grupoSala.id}/salas`},
                    {title: sala.titulo}
                ]} 
            />
            {(grupoSala.observacoes_salas) 
                ?  (
                    <div 
                        className="mb-3 text-info" 
                        dangerouslySetInnerHTML={{ __html: grupoSala.observacoes_salas }} 
                    />
                )
                : ''}
            <SalaView sala={sala} />
        </Container>
    );
}

export default GrupoSalaSalaView;