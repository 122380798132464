import React from 'react';
import {
	BrowserRouter as Router,
} from "react-router-dom";

import { AuthProvider } from './contexts/AuthContext';

import Routes from './routes';

//import GlobalStyle from './styles/GlobalStyle';

import 'bootstrap/dist/css/bootstrap.min.css';

import GlobalStyle from './styles/GlobalStyle';

function App() {

	return (
		<>
			<GlobalStyle />
			<Router>
				<AuthProvider>
					<Routes />
				</AuthProvider>
			</Router>
		</>
		
	);
}

export default App;
