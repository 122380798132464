import React from 'react';

import { CustomButton } from './styles';

const UButton = ({children, ...rest}) => {
    return (
        <CustomButton outline {...rest}>
            {children}
        </CustomButton>
    );
}

export default UButton;
