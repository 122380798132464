import React from 'react';

import {ListGroup, ListGroupItem} from 'reactstrap';

import {
    ContentMateriaisLinks
} from './styles';

import {AttachFile as FileIcon, Link as LinkIcon} from '@material-ui/icons';

function SalaMateriais({materiais}) {
    
    return (
        <>
        {materiais.length
            ? (
                <ContentMateriaisLinks>
                    <hr />
                    <h6>Materiais e links adicionais</h6>
                    <ListGroup>
                        {materiais.map((matlink, i) => (
                            <ListGroupItem className="p-0" key={i}>
                                <a href={matlink.link_material} className="btn btn-link w-100 text-left p-3" target="_blank" rel="noopener noreferrer">
                                    {matlink.tipo.id === 3
                                        ? <FileIcon />
                                        : <LinkIcon />
                                    }
                                    <span className="pl-2">{matlink.descricao}</span>
                                </a>
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                </ContentMateriaisLinks>
            ) : null
        }
        </>
    )

}

export default SalaMateriais;