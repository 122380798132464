import React, {useState} from 'react';

import { 
    Form,
    InputGroupAddon,
    InputGroupText
} from 'reactstrap';

import { Container, Logo, Title, Subtitle, SInputGroup, SInput } from './styles';

import {useAuth} from './../../contexts/AuthContext';

import Loading from './../../components/Loading';
import UButton from './../../components/UButton';
import FormError from './../../components/FormError';

import logo from './../../assets/logoURI_192.png';

import {MailOutline, VpnKey} from '@material-ui/icons';

function Login() {

    const { loading, signInUser, loginError } = useAuth();

    const [loginData, setLoginData] = useState({
        email: '',
        codigo_acesso: ''
    });

    function handleChange(evt){
        setLoginData({
            ...loginData,
            [evt.target.name]: evt.target.value
        });
    }

    function handleSubmit(evt){
        evt.preventDefault();

        if(loginData.email === '' || loginData.codigo_acesso === ''){
            document.getElementById("formError").innerHTML = 'Por favor, preencha todos os dados!';
            return false;
        }

        signInUser(loginData.email, loginData.codigo_acesso);

        return false;
    }

    if(loading)
        return <Loading />

    return (
        <Container>
            <Form onSubmit={handleSubmit} className="p-4">
                <Logo src={logo} alt="Logo" />
                <Title>PLAY URI</Title>

                <Subtitle>Acessar</Subtitle>

                <SInputGroup className="mb-3">
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                            <MailOutline fontSize="small" />
                        </InputGroupText>
                    </InputGroupAddon>
                    <SInput type="email" name="email" placeholder="Informe o seu e-mail" value={loginData.email} onChange={handleChange} />
                </SInputGroup>

                <SInputGroup className="mb-3">
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                            <VpnKey fontSize="small" />
                        </InputGroupText>
                    </InputGroupAddon>
                    <SInput type="text" name="codigo_acesso" placeholder="Informe o seu código" value={loginData.codigo_acesso} onChange={handleChange} />
                </SInputGroup>

                <FormError error={loginError} />
                
                <UButton block>Entrar</UButton>
            </Form>
        </Container>
    );
}

export default Login;