import React from 'react';

import {
    Container,
    Dot
} from './styles';

const SalaItemStatus = ({status}) => {
    return (
        <Container>
            <Dot className={`${status}`} />
        </Container>
    );
}

export default SalaItemStatus;
