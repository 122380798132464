import React, { useState, useEffect, createElement } from 'react';

import {Container} from 'reactstrap';
import {Redirect} from 'react-router-dom';

import api from '../../services/api';

import Loading from './../../components/Loading';
import UBreadcrumb from './../../components/UBreadcrumb';

import USalaView from './../../components/SalaView';

function SalaView({match}) {

	const [loading, setLoading] = useState(true);
	const [sala, setSala] = useState({});
	const [salaRedirect, setSalaRedirect] = useState(false);

	const sala_id = match.params.sala_id;

	useEffect(() => {
		(async () => {
			try{
				const {data} = await api.get(`/salas/${sala_id}`);
				setSala(data.sala);

				setSalaRedirect((data.sala.materialPrincipal && data.sala.materialPrincipal.tipo.id == 2));

				setLoading(false);
			} catch(error){
				console.log(error);
			}
		})();
	}, [sala_id]);

	if(salaRedirect){
		window.location.href= sala.materialPrincipal.link_material;
		return <Loading text="Redirecionando..." />
	}

	if(loading)
		return <Loading text="Carregando sala..." />

	if(!sala)
		return <Redirect to="/" />

	return (
		<Container>
			<UBreadcrumb 
				tituloEvento={sala.evento_nome}
				paths={[
					{title: 'Início', to: '/'},
					{title: sala.titulo}
				]} 
			/>

			<USalaView sala={sala} />
		</Container>
	);
}

export default SalaView;