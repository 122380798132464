import React from 'react';

import {hourFromDate, periodFromDates} from './../../services/utils';

import { 
    ListItem,
    ListItemButton,
	ListItemContent,
    ListItemSubtitle,
    ListItemBlocked,
    ListItemButtonBlocked
} from './styles';

import SalaItemStatus from './../SalaItemStatus';

import {useAuth} from './../../contexts/AuthContext';

const GrupoSalaPlaylistSalaItem = ({sala, salaAtiva, handleChangeSala}) => {

    const {isAdmin} = useAuth();

    let iconClass = "off";
    let isSalaAtiva = ((salaAtiva) && sala.id === salaAtiva.id);
    let isBlocked = true;

    if(sala.is_online){
        iconClass = "on";
        isBlocked = false;
    } else if (sala.situacao === 'aberta') {
        iconClass = "on";
        isBlocked = false;
    } else if (sala.situacao === 'encerrada') {
        iconClass = "ok";
        isBlocked = false;
    } else if (sala.situacao === 'embreve' || sala.situacao === 'aguardando'){
        iconClass = "off";
        isBlocked = true;
    }

    if (isSalaAtiva && !isBlocked){
        iconClass = "active";
    }

    let itemIcon = <SalaItemStatus status={iconClass} />;

    let situacaoSala;

    switch(sala.situacao){
        case "aberta": situacaoSala = "Aberta"; break;
        case "embreve": situacaoSala = "Em breve"; break;
        case "aguardando": situacaoSala = "Aguardando"; break;
        default: situacaoSala = "Encerrado"; break;
    }

    let itemContent = (
        <ListItemContent>
            {sala.titulo}
            <ListItemSubtitle>
                {`${hourFromDate(sala.inicio)} - ${hourFromDate(sala.termino)}`} | {periodFromDates(sala.inicio, sala.termino)} - {situacaoSala}
            </ListItemSubtitle>
        </ListItemContent>
    );

    if(isAdmin)
        isBlocked = false;

    let item;

    if (isBlocked){
        item = (
            <ListItemBlocked title="Aguarde...">
                <ListItemButtonBlocked>
                    {itemIcon}
                    {itemContent}
                </ListItemButtonBlocked>					
            </ListItemBlocked>
        );
    }else{
        item = (
            <ListItem className={`${isSalaAtiva ? 'sala-active' : ''}`} >
                <ListItemButton
                    onClick={() => handleChangeSala(sala)}
                >
                    {itemIcon}
                    {itemContent}
                </ListItemButton>					
            </ListItem>
        );
    }
    
    return item;
}

export default GrupoSalaPlaylistSalaItem;
