import React, {useState} from 'react';

import ReactPlayer from 'react-player/lazy';
import youtube from './configs/youtube';

import {
    Button
} from 'reactstrap';

//import LogoURI from './../../../assets/logoUri.png';
import LogoURI from './../../../assets/logoURI_192.png';

import {
    Container,
    OverlayContent,
    OverlayImg,
    OverleyText,
    OverlayActions
} from './styles';

import UAnimated from './../../UAnimated';

import ReplayIcon from '@material-ui/icons/Replay';

function UoVideo ({video, onEndedVideo}){

    const [overlayOpen, setOverlayOpen] = useState(true);
    const [contentVideoOpen, setContentVideoOpen] = useState(false);

    const [playVideo, setPlayVideo] = useState(true);
    const [endedVideo, setEndedVideo] = useState(false);

    function handleReadyVideo() {
        setOverlayOpen(false);
        setContentVideoOpen(true);
        setPlayVideo(true);
    }

    function handleEndedVideo(){
        console.log('Terminou');
        setOverlayOpen(true);
        setContentVideoOpen(false);
        setPlayVideo(false);
        setEndedVideo(true);

        onEndedVideo(video);
    }

    function handleShowAgain(){
        console.log('Ver novamente')
        setEndedVideo(false);
        setOverlayOpen(false);
        setContentVideoOpen(true);
        setPlayVideo(true);
    }

    return (
        <Container>
            <UAnimated 
                animationIn="zoomIn" 
                animationOut="fadeOut" 
                isVisible={overlayOpen}
            >
                <OverlayContent>
                    <OverlayImg src={LogoURI} alt="URI" />
                    <OverleyText>PLAY URI</OverleyText>
                    {(endedVideo && (
                        <OverlayActions>
                            <Button 
                                outline 
                                color="dark" 
                                className="px-5"
                                onClick={handleShowAgain}
                            >
                                <ReplayIcon />
                                <span className="pl-2">Ver novamente</span>
                            </Button>
                        </OverlayActions>
                    ))}
                </OverlayContent>
            </UAnimated>
            <UAnimated 
                animationIn="fadeIn" 
                animationOut="fadeOut" 
                isVisible={contentVideoOpen}
            >
                <ReactPlayer 
                    url={video.link_material} 
                    width="100%" 
                    height="450px"
                    playing={playVideo}
                    controls={true}
                    config={{
                        youtube: youtube,
                    }}
                    onEnded={handleEndedVideo}
                    onReady={handleReadyVideo}
                />                
            </UAnimated>
        </Container>
    );
}

export default UoVideo;
