import api from './api';

export function signInToken(token){
    return api.post('/auth-token', null, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export function signInUser(email, token){
    return api.post('/login', {
        email: email,
        token_acesso: token
    });
}

export function refreshToken(){
    return api.post('/refresh-token');
}