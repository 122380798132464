import React from 'react';

import {useAuth} from './../contexts/AuthContext';

import AppRoutes from './AppRoutes';
import AuthRoutes from './AuthRoutes';

import Loading from './../components/Loading';

const Routes = () => {

    const {signed, loadingAuth} = useAuth();

    if(loadingAuth)
        return <Loading />

    return signed ? <AppRoutes /> : <AuthRoutes />;
}

export default Routes;
