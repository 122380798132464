import styled from 'styled-components';

export const Container = styled.div`
    padding: 0 10px;
`;

export const Dot = styled.div`
    width: 12px;
    height: 12px;
    border-radius: 50%;

    &.on {
        background-color: green !important;
    }

    &.ok {
        background-color: #4abfec;
    }

    &.active {
        background-color: #005588;
    }

    &.off {
        background-color: #777777;
    }
`;
