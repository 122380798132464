import React, {useState, useEffect} from 'react';

import {Row} from 'reactstrap';

import { Container, ContainerVideo } from './styles';

import UoVideo from './Video';
import PlayList from './PlayList';

function VideoPlayer({videos}) {

    const [viewPlayList, setViewPlayList] = useState(true);
    const [videoAtivo, setVideoAtivo] = useState({
        link_material: null
    });

    useEffect(() => {
        setVideoAtivo(videos[0]);

        setViewPlayList((videos.length > 1));
    }, [videos]);

    function changeVideoAtivo(video){
        setVideoAtivo(video);
    }

    function changeOnEndedVideo(video){
        console.log("Terminou o video:");
        console.log(video);
        console.log(videos);
    }

    return (
        <Container>
            <Row className="mb-3">
                <div className={`col-12 col-md col-lg ${viewPlayList && 'pl-md-0'}`}>
                    <ContainerVideo>
                        <UoVideo video={videoAtivo} onEndedVideo={changeOnEndedVideo} />
                    </ContainerVideo>
                </div>
                {viewPlayList && (
                    <div className="col-12 col-md-4 col-lg-3 px-md-0">
                        <PlayList videos={videos} changeVideo={changeVideoAtivo} />
                    </div>
                )}
            </Row>
        </Container>
    );
}

export default VideoPlayer;