import styled from 'styled-components';

import theme from './../../styles/theme';

import {PlayURIDark} from './../../styles/GlobalStyle';
import { Input, InputGroup } from 'reactstrap';

export const Logo = styled.img`
    height: 100px;

    margin-bottom: 10px;
`;

export const Title = styled(PlayURIDark)`
    color: #eeeeee;

    margin-bottom: 20px;
`;

export const Subtitle = styled.h5`

`;

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    text-align: center;

    background-color: ${theme.primary};

    form {
        width: 100%;
        max-width: 400px;

        border: 1px solid #ffffff;
        border-radius: 10px;

        background-color: #eeeeee;

        img {
            max-height: 100px;
        }

        h1 {
            margin-bottom: 20px;
        }
    }
`;

export const SInputGroup = styled(InputGroup)`
    span {
        color: ${theme.primary};
        border-color: ${theme.primary};
    }
`;

export const SInput = styled(Input)`
    color: ${theme.primary};
    border-color: ${theme.primary};

    background-color: transparent;

    &:focus, &:visited {
        background-color: #fff;

        border-color: ${theme.secondary};
        box-shadow: none;
    }
`;
