import styled from 'styled-components';

export const Wrapper = styled.div`
    position: relative;
    height: 100%;
    min-height: 100%;
    padding-top: 60px;
    padding-bottom: 40px;
`;

export const Main = styled.main`
    height: 100%;
    min-height: 100%;
`;

export const Footer = styled.footer`
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    text-align: center;
    padding: 10px;
    font-size: 8pt;
    width: 100%;
`;
