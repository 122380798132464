import theme from './../../../../styles/theme';

const youtube = {
    playerVars: { 
        showinfo: 0,
        autoplay: 1,
        iv_load_policy: 3,
        cc_load_policy: 0,
        rel: 0,
        list: null,
        listType: null,
        color: theme.accent
    },
    embedOptions: {
        showinfo: 0,
        autoplay: 1,
        iv_load_policy: 3,
        cc_load_policy: 0,
        rel: 0,
        list: null,
        listType: null,
        color: theme.accent
    }
}

export default youtube;