import styled from 'styled-components';
import { Navbar, NavbarText, NavbarBrand, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';

import theme from './../../styles/theme';
import {PlayURIWhite} from './../../styles/GlobalStyle';

export const PNavBar = styled(Navbar)`
    background-color: ${theme.primary};
    color: #ffffff;

    a{
        color: ${theme.textColor};
        transition: all 0.2s;

        &:hover{
            color: ${theme.detail};
        }
    }
`;

export const PNavBrand = styled(NavbarBrand)`
    color: ${theme.textColor};

    transition: all 0.2s;
`;

export const PNavBrandLogo = styled.img`
    height: 30px;
    width: 30px;
`;

export const PNavBrandTitle = styled(PlayURIWhite)`
    color: ${theme.primary};
    font-size: 13pt;
    display: inline;
    padding-left: 15px;
    margin: 0;
`;

export const PDropdownToggle = styled(DropdownToggle)`
    color: ${theme.textColor};

    transition: all 0.2s;
`;

export const PDropdownMenu = styled(DropdownMenu)`
    width: 100%;
    background-color: ${theme.primary};
    color: ${theme.textColor};

    transition: all 0.2s;
`;

export const PDropdownItem = styled(DropdownItem)`
    color: ${theme.textColor};
    transition: all 0.2s;

    &:hover, &:focus, &:visited {
        background-color: transparent;
        color: ${theme.accent};
        border: 0;
    }
`;

export const PNavButton = styled.a`
    color: #ffffff;
`;

export const PNavbarText = styled(NavbarText)`
    color: ${theme.detail};
`;
