import styled from 'styled-components';
import theme from './../../styles/theme';

export const Container = styled.div`
    //margin-bottom: 25px;
`;

export const HeaderCollapse = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
`;
export const IconCollapse = styled.div`
    padding: 20px 0 20px 20px;
    color: #888888;
`;

export const Content = styled.div``;

export const ContentTitulo = styled.h5`
    margin-bottom: 5px;
`;

export const ContentTituloHora = styled.div`
    font-size: 10pt;
    margin-bottom: 15px;

    color: ${theme.detail};
`;
