const theme = {
    primary: '#002334',
    secondary: '#006699',
    detail: '#88A096',
    accent: '#006699',
    accentDark: '#002334',
    textColor: '#FFFAFF'
};

//colors = https://coolors.co/006699-fffaff-88a096-002334-db5461

export default theme;