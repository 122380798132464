import styled from 'styled-components';
import {
    ListGroup,
    ListGroupItem
} from 'reactstrap';

export const Container = styled.div`
	
`;

export const ContentList = styled.div`
    //box-shadow: 0px 1px 4px 1px rgba(0,0,0,0.2);
`;

export const ContentListTitle = styled(ListGroupItem)`
    padding: 5px;
    color: #000000;
    background-color: #dadada;
    text-align: center;
`;

export const List = styled(ListGroup)`
    border-radius: 0;
`;

export const Legenda = styled.div`
    margin: 20px 0;
    font-size: 10pt;
`;
export const LegendaItem = styled.div`
    display: inline-flex;
    align-items: center;
    width: 100%;
    margin: 5px;
`;
