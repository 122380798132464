import styled from 'styled-components';

import theme from './../../styles/theme';
import {PlayURIWhite} from './../../styles/GlobalStyle';

export const Logo = styled.img`
    height: 80px;
    width: 80px;
`;

export const Title = styled(PlayURIWhite)`
    color: ${theme.primary};
`;

export const Container = styled.div`
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1100;
    background-color: ${theme.primary};
    color: #ffffff;
    transition: all 0.25s ease-out;
    
    opacity: 1;

    .content {
        width: 300px;
        max-width: 300px;
        text-align: center;

        img {
            margin-bottom: 2%;
        }

        h1 {
            margin-bottom: 5%;
        }

        
        .lds-roller {
            display: inline-block;
            position: relative;
            width: 70px;
            height: 70px;
            }
            .lds-roller div {
            animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            transform-origin: 40px 40px;
            }
            .lds-roller div:after {
            content: " ";
            display: block;
            position: absolute;
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background: #fff;
            margin: -4px 0 0 -4px;
            }
            .lds-roller div:nth-child(1) {
            animation-delay: -0.036s;
            }
            .lds-roller div:nth-child(1):after {
            top: 63px;
            left: 63px;
            }
            .lds-roller div:nth-child(2) {
            animation-delay: -0.072s;
            }
            .lds-roller div:nth-child(2):after {
            top: 68px;
            left: 56px;
            }
            .lds-roller div:nth-child(3) {
            animation-delay: -0.108s;
            }
            .lds-roller div:nth-child(3):after {
            top: 71px;
            left: 48px;
            }
            .lds-roller div:nth-child(4) {
            animation-delay: -0.144s;
            }
            .lds-roller div:nth-child(4):after {
            top: 72px;
            left: 40px;
            }
            .lds-roller div:nth-child(5) {
            animation-delay: -0.18s;
            }
            .lds-roller div:nth-child(5):after {
            top: 71px;
            left: 32px;
            }
            .lds-roller div:nth-child(6) {
            animation-delay: -0.216s;
            }
            .lds-roller div:nth-child(6):after {
            top: 68px;
            left: 24px;
            }
            .lds-roller div:nth-child(7) {
            animation-delay: -0.252s;
            }
            .lds-roller div:nth-child(7):after {
            top: 63px;
            left: 17px;
            }
            .lds-roller div:nth-child(8) {
            animation-delay: -0.288s;
            }
            .lds-roller div:nth-child(8):after {
            top: 56px;
            left: 12px;
            }
            @keyframes lds-roller {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
            }

    }
`;
