import styled from 'styled-components';

import {Link} from 'react-router-dom';
import {Container, Card} from 'reactstrap';
import theme from './../../styles/theme';

export const SContainer = styled(Container)`
    
`;

export const STituloContent = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const STitulo = styled.h5`
    margin-bottom: 1rem;
`;

export const GrupoSalaItem = styled(Link)`
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
`;

export const GrupoSalaItemContent = styled(Card)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding: 10px 10px 10px 20px;
    margin-bottom: 10px;
    border-color: ${theme.secondary};
    border-radius: 0;

    color: ${theme.secondary};

    transition: all 0.2s;

    &:hover {
        text-decoration: none;
        background-color: ${theme.secondary};
        color: ${theme.textColor};

        .text-muted {
            color: #9ddfff !important;
        }
    }
`;

export const GrupoSalaItemContentDetail = styled.div`
    font-size: 80%;
`;
