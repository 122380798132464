import React, {useEffect, useState} from 'react';

import { Link, Redirect } from 'react-router-dom';

import {Container} from 'reactstrap';

import {time_to_reload_salas} from './../../config';
import api from './../../services/api';

import UBreadcrumb from './../../components/UBreadcrumb';
import Loading from './../../components/Loading';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CpGrupoSalaPlaylistSalas from './../../components/GrupoSalaPlaylistSalas';

function GrupoSalaPlaylist({match}) {

    const grupo_sala_id = match.params.grupo_sala_id;

    const [loading, setLoading] = useState(true);
    const [grupoSala, setGrupoSala] = useState({});
    const [salas, setSalas] = useState([]);

    async function fetchSalas(){
        const {data} = await api.get(`/grupo/${grupo_sala_id}/salas/list`);
        setGrupoSala(data.grupossalas);
        setSalas(data.salas);

        setLoading(false);
    }

    useEffect(() => {
        fetchSalas();
    }, [grupo_sala_id]);

    useEffect(() => {

        const timeToReload = setInterval(() => {
            fetchSalas();
        }, time_to_reload_salas);

        return () => {
            clearInterval(timeToReload);
        }
        
    });

    if(loading)
        return <Loading text="Buscando salas..." />

    if(!grupoSala || !salas.length)
        return <Redirect to="/" />

        /* 
                    {title: 'Início', to: '/'},
                    {title: grupoSala.titulo}*/

    return (
        <Container>
            
            <UBreadcrumb 
                tituloEvento={grupoSala.evento_nome}
                paths={[]}
            />

            <div className="d-flex align-items-center">
                <Link to="/" className="btn btn-outline-primary">
                    <ChevronLeftIcon className="pr-2"/>Voltar
                </Link>
                <h6 className="mb-0 ml-2">{grupoSala.titulo}</h6>
            </div>

            <hr />

            {(grupoSala.observacoes)
                ? (
                    <div className="mb-3 text-info">
                        <h5 
                            dangerouslySetInnerHTML={{ __html: grupoSala.observacoes }} 
                        />
                    </div>
                )
                : ''
            }
            
            <CpGrupoSalaPlaylistSalas salas={salas} />

        </Container>
    );
}

export default GrupoSalaPlaylist;