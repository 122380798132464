import React, {useEffect} from 'react';

import {useAuth} from './../../contexts/AuthContext';

import Loading from './../../components/Loading';

function Integracao(props) {

	const { signInToken } = useAuth();

	const {token} = props.match.params;

	useEffect(() => {
		signInToken(token);
		return () => {};
	});

	return <Loading />;
}

export default Integracao;