import React, {useEffect, useState} from 'react';

import { 
	Container,
	ContentList,
	ContentListTitle,
	List,
	Legenda,
	LegendaItem
} from './styles';

import Loading from './../Loading';

import DetailsSala from './../GrupoSalaPlaylistSalasDetail';
import ListItemSala from './../GrupoSalaPlaylistSalasItem';

import SalaItemStatus from './../../components/SalaItemStatus';

function GrupoSalaPlaylistSalas({salas}) {

	const [loadingPage, setLoading] = useState(true);
	const [salaAtiva, setSalaAtiva] = useState(null);

	useEffect(() => {

		//mapeia as salas para verificar qual está online no momento
		salas = salas.map((sala) => {
			if(sala.situacao === 'aberta'){
				sala.is_online = true;
			}

			return sala
		})

		console.log(salas);

		//verifica se existe uma sala selecionada pelo usuário
		let sessionSalaActiveId = getSessionSalaAtiva();
		console.log("sessionSalaActiveId", sessionSalaActiveId);

		//se existir
		if(sessionSalaActiveId){
			//filtra a sala ativa pela que estava em sessão
			setSalaAtiva(salas.filter((sala) => {
				return sala.id == sessionSalaActiveId
			})[0])
		}else{
			//se não, a sala ativa no momento é a que está online
			let salaOnline = salas.filter((sala) => {
				return sala.is_online
			});

			if(salaOnline)
				setSalaAtiva(salaOnline[0])
		}

		setLoading(false);

	}, [salas]);

	function getSessionSalaAtiva(){
		return sessionStorage.getItem("@PUri:salaAtiva");
	}

	function setSessionSalaAtiva(sala){
		sessionStorage.setItem("@PUri:salaAtiva", sala.id);
	}

	function handleChangeSala(sala) {
		setSalaAtiva(sala);
		setSessionSalaAtiva(sala);

		window.scrollTo(0,100);
	}

	if(loadingPage)
		return <Loading text="Configurando sala..." />

	return (
		<Container>
			
			<DetailsSala sala={salaAtiva} />
			
			<ContentList>
				<List>
					<ContentListTitle>
						Listagem de Apresentações
					</ContentListTitle>
					{salas.map((sala, key) => (
						<ListItemSala key={key} sala={sala} salaAtiva={salaAtiva} handleChangeSala={handleChangeSala} />
					))}
				</List>
			</ContentList>

			<Legenda>
				<LegendaItem><SalaItemStatus status="on" /> Aberta - Sala com conteúdo disponível neste momento</LegendaItem>
				<LegendaItem><SalaItemStatus status="off" /> Em breve - Sala que irá abrir em pouco tempo</LegendaItem>
				<LegendaItem><SalaItemStatus status="off" /> Aguardando - Sala que está aguardando o período de início</LegendaItem>
				<LegendaItem><SalaItemStatus status="ok" /> Encerrada - Sala que já passou do período de abertura. Ainda é possivel visualizar seu conteúdo</LegendaItem>
			</Legenda>
		</Container>
	);
}

export default GrupoSalaPlaylistSalas;