import React from 'react';

import { Container, Logo, Title } from './styles';

import logo from './../../assets/logoURI_192b.png';

function Loading({text}) {
	return (
		<Container>
			<div className="content">
				<Logo src={logo} alt="PLAY URI" />
				<Title>PLAY URI</Title>
				<p>{text ?? 'Carregando...'}</p>
				<div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
			</div>
		</Container>
	);
}

export default Loading;