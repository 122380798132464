import React, {useState} from 'react';

import {
    PNavBar, 
    PNavButton, 
    PNavBrand, 
    PNavBrandLogo,
    PNavBrandTitle,
    PDropdownToggle, 
    PDropdownMenu, 
    PDropdownItem
} from './styles';

import {
    Container,
    Collapse,
    Nav,
    UncontrolledDropdown
} from 'reactstrap'

import logo from './../../assets/logoURI_56b.png';

import { useAuth } from '../../contexts/AuthContext';

import {Menu} from '@material-ui/icons';

const NavBarTopo = () => {

    const {user, signOut, isAdmin} = useAuth();

    const [collapsed, setCollapsed] = useState(true);
    const toggleNavbar = () => setCollapsed(!collapsed);

    return (
        <div>
            <PNavBar className="fixed-top" expand="md">
                <Container>
                    <PNavBrand href="/">
                        <PNavBrandLogo src={logo} title="PLAY URI" />
                        <PNavBrandTitle>PLAY URI</PNavBrandTitle>
                    </PNavBrand>
                    <PNavButton className="navbar-toggler" onClick={toggleNavbar}>
                        <Menu />
                    </PNavButton>
                    <Collapse isOpen={!collapsed} navbar>
                        <Nav className="ml-auto" navbar>
                            <UncontrolledDropdown nav inNavbar>
                                <PDropdownToggle nav caret>
                                    <span className="pr-2">
                                        {user.name} 
                                        {(isAdmin) && (<small className="text-muted pl-2">Admin.</small>)}
                                    </span>
                                </PDropdownToggle>
                                <PDropdownMenu right>
                                    <PDropdownItem onClick={() => signOut()}>
                                        Sair
                                    </PDropdownItem>
                                </PDropdownMenu>
                            </UncontrolledDropdown>
                        </Nav>
                    </Collapse>
                </Container>
            </PNavBar>
        </div>
    );
}

export default NavBarTopo;
