import styled from 'styled-components';

import {ListGroupItem} from 'reactstrap';

export const ListItem = styled(ListGroupItem)`
    padding: 0;
    border-radius: 0;

    transition: all 0.3;

    &.sala-active {
        border: 1px solid #005588 !important;
        color: #005588;
    }

    &:hover {
        color: #005588;
        background-color: #e1f0ff;
    }
`;

export const ListItemButton = styled.div`
    display: flex;
    align-items: center;
    text-align: left;
    padding: 5px 0;
    cursor: pointer;
`;

export const ListItemContent = styled.div`
    padding: 0 5px;
    border-left: 1px solid rgba(0,0,0,.125);;
    width: 100%;
`;

export const ListItemSubtitle = styled.div`
    font-size: 10pt;
    color: #b0b0b0;
`;


export const ListItemBlocked = styled(ListItem)`
    color: #777;
    background-color: #eee;
`;

export const ListItemButtonBlocked = styled(ListItemButton)`
    color: #777;
    background-color: #eee;
    cursor: default;
`;