import { Breadcrumb } from 'reactstrap';
import styled from 'styled-components';

export const Container = styled.div`
    padding: .75rem 1rem;
    //background-color: #e9ecef;
    border-radius: 5px;
    margin-bottom: 1rem;
`;

export const SBreadcrumb = styled(Breadcrumb)`

    ol.breadcrumb {
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: 0 auto;
        background-color: transparent !important;

        li {
            text-transform: uppercase;
            font-size: 10pt;
        }
    }
`;

export const TituloEvento = styled.div`
    text-align: center;
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 14pt;
`;
