import React from 'react';

import { 
    Container,
    ContentMessage,
    Message,
    Submessage
} from './styles';

import SalaDetails from './../SalaDetails';
import SalaMateriais from './../SalaMateriais';
import VideoPlayer from './../VideoPlayer';
import UPDFViewer from './../../components/UPDFViewer';

function GrupoSalaPlaylistSalasDetail({sala}) {

    if(!sala){
        return (
            <ContentMessage>
                <Message>Não há nenhuma apresentação disponível no momento!</Message>
                <Submessage>Confira na listagem de apresentações, abaixo, para verificar os horários de cada apresentação.</Submessage>
            </ContentMessage>
        )
    }

    const videos = sala.materiais.filter((material) => {
        return material.tipo.id === 1
    });

    //materiais que não são principais
    const materiaisLinks = sala.materiais.filter((material) => {
        return (material.tipo.id === 3 || material.tipo.id === 4) && !material.principal;
    });

    //pega um único material principal
    const materialPrincipal = sala.materiais.filter((material) => {
        return (material.tipo.id === 3 || material.tipo.id === 4) && material.principal;
    }).shift();

    let content;

    if(videos.length){
        content = <VideoPlayer videos={videos} />
    }else if(materialPrincipal){
        content = <UPDFViewer style={{width: "100%", height: "450px"}} url={`${materialPrincipal.link_material}`}/>;
    }else{
        content = (
            <div className="border mb-2 p-4">
                <h5 className="mb-0 text-muted text-center">Apresentação na sala do Google Meet.</h5>
            </div>
        )
    }

    return (
        <Container>
            {content}

            <SalaDetails sala={sala} />

            <SalaMateriais materiais={materiaisLinks} />
        </Container>
    );
}

export default GrupoSalaPlaylistSalasDetail;