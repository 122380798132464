import React from 'react';

import {
	Switch,
	Route,
    Redirect,
} from "react-router-dom";

import Login from './../pages/Login';
import Integracao from './../pages/Integracao';

const AuthRoutes = () => {
    return (
        <Switch>
            <Route path="/login" exact={true} component={Login} />
            <Route path="/integracao/:token" component={Integracao} />
            <Redirect to="/login" />
        </Switch>
    );
}

export default AuthRoutes;
