import styled from 'styled-components';

import {
    Card,
    CardTitle,
    CardSubtitle,
    CardText,
    CardLink,
    CardFooter,
    CardBody
} from 'reactstrap';

import theme from './../../styles/theme';

export const Sala = styled(Card)`
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
    margin-bottom: 1rem;
    height: 100%;
`;

export const SalaHour = styled.div`
    position: absolute;
    top: -10px;
    right: -2px;

    display: flex;
    align-items: center;

    padding: 2px 8px;
    font-size: 9pt;

    background-color: ${theme.accent};
    color: ${theme.textColor};
`;

export const SalaBody = styled(CardBody)`
    padding-bottom: 0;
    text-align: center;
`;

export const SalaDate = styled(CardSubtitle)`
    font-size: 10pt;
    padding: 15px 0 10px 0;
    color: ${theme.detail};
`;

export const SalaTitle = styled(CardTitle)`
    font-size: 12pt;
    font-weight: 600;
`;

export const SalaText = styled(CardText)`
    
`;

export const SalaFooter = styled(CardFooter)`
    background-color: transparent;
    text-align: center;
    padding-bottom: 0;
`;

export const SalaLink = styled(CardLink)`
    color: ${theme.accent};
    font-weight: 700;

    transition: all 0.3s;

    &:hover{
        color: ${theme.accentDark};
    }
`;

const TextFooter = styled.p`
    font-size: 12pt;
    margin: 0;
`;

export const SalaAguardando = styled(TextFooter)`
    color: ${theme.detail};
`;

export const SalaEncerrada = styled(TextFooter)`
    color: #dddddd;
`;
