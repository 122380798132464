import React from 'react';
import { Link } from 'react-router-dom';
import {BreadcrumbItem} from 'reactstrap';

import { 
    Container,
    SBreadcrumb,
    TituloEvento
} from './styles';

function UBreadcrumb({tituloEvento, paths}) {

    const pathsLength = paths.length;

    return (
        <Container>
            <TituloEvento>{tituloEvento}</TituloEvento>
            <SBreadcrumb>
                {paths.map((path, i) => (
                    <BreadcrumbItem active={(pathsLength === i + 1)} key={(i+1)}>
                        {(path.to) 
                            ? (
                                <Link to={path.to}>{path.title}</Link>
                            ) 
                            : path.title
                        }
                    </BreadcrumbItem>
                ))}
            </SBreadcrumb>
        </Container>
    );
}

export default UBreadcrumb;