import styled from 'styled-components';

import {ListGroup, ListGroupItem} from 'reactstrap';
import theme from '../../../styles/theme';

export const UoListGroup = styled(ListGroup)`
    height: 100%;
    border: 1px solid rgba(0,0,0, 0.125);
    border-radius: 0;

    margin-bottom: 10px;
    max-height: 450px;
    overflow-y: auto;
`;

export const UoListGroupItem = styled(ListGroupItem)`

    border-left: 0px;
    border-right: 0px;

    cursor: pointer;

    transition: all 0.2s ease-out;

    &.active {
        background-color: #efefef;
        color: ${theme.secondary};

        border-color: inherit;

        &:before {
            content: '';
            position: absolute;
            display: inline-block;
            vertical-align: middle;
            left: -14px;
            width: 20px;
            height: 20px;
            transform: rotate(45deg);
            background-color: ${theme.secondary};
        }
    }

    &:hover {
        color: ${theme.secondary};
    }

    &:first-child {
        border-top: 0px;
    }
`;
