import React, {useState, useEffect} from 'react';

import { UoListGroup, UoListGroupItem } from './styles';

function Playlist({videos, changeVideo}) {

	const [videoActive, setVideoActive] = useState({});

	useEffect(() => {
		setVideoActive(videos[0]); //no inicio é sempre o primeiro vídeo
	}, [videos]);

	function handleClickGroupItem(video){
		setVideoActive(video);
		changeVideo(video);
	}

	return (
		<UoListGroup>
			{videos.map((video, i) => (
				<UoListGroupItem 
					key={i} 
					active={(videoActive.id === video.id)} 
					className="justify-content-between" 
					onClick={() => handleClickGroupItem(video)}
					title={`Acessar ${video.descricao}`}
				>
					{video.descricao}
				</UoListGroupItem>
			))}
	  	</UoListGroup>
	);
}

export default Playlist;