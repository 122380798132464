import styled from 'styled-components';

import {PlayURIDark} from './../../../styles/GlobalStyle';

export const Container = styled.div`
    border: 1px solid #666666;
`;

export const OverlayContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 450px;
    max-height: 450px;
`;

export const OverlayImg = styled.img`
    height: 100px;
    margin-bottom: 5px;
`;

export const OverleyText = styled(PlayURIDark)`
    color: #fff;
    margin-bottom: 20px;
`;

export const OverlayActions = styled.div``;
