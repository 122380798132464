import React from 'react';

const FormError = ({error}) => {
    return (
        <div id="formError" className="text-danger mb-2">
            {(error) ?? error}
        </div>
    );
}

export default FormError;
