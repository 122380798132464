export function hourFromDate(date){
    let splipted = date.split(" ");

    return splipted[1];
}

export function periodFromDates(dateStart, dateEnd){
    let start = dateStart.split(" ")[0];
    let end = dateEnd.split(" ")[0];

    return (start === end) ? start : `${start} - ${end}`;
}