import styled from 'styled-components';

export const Container = styled.div`
    padding-bottom: 5rem;
`;

export const ContentMessage = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100px;

    padding: 10px;
`;

export const Message = styled.h5`
    margin-bottom: 0;
`;

export const Submessage = styled.p`
    font-size: 85%;
    color: #ff3333;
`;
