import React, { useEffect, useState } from 'react';

import {Animated} from 'react-animated-css';

const UAnimated = ({children, isVisible,...rest}) => {

    const [display, setDisplay] = useState('block');

    useEffect(() => {
        setDisplay(isVisible ? 'block' : 'none');
    }, [isVisible]);

    return (
        <Animated {...rest} style={{display: display}}>
            {children}
        </Animated>
    );
}

export default UAnimated;
