import React from 'react';

function UPDFViewer({style, url}) {
    return (
        <>
            <iframe style={style} src={url} allowFullScreen title="Documento">
                <p>Ocorreu um erro ao carregar o documento. <a href={url} target="_blank" rel="noopener noreferrer">Clique aqui para visualiza-lo.</a></p>
            </iframe>

            <div className="text-right mb-2">
                <a href={url} target="_blank" rel="noopener noreferrer">Baixar documento</a>
            </div>
        </>
    );
}

export default UPDFViewer;