import React from 'react';

import {useAuth} from './../../contexts/AuthContext';

import {
    Col
} from 'reactstrap';

import { 
    Sala, 
    SalaHour,
    SalaBody,
    SalaTitle, 
    SalaDate,
    SalaLink, 
    SalaFooter, 
    SalaAguardando, 
    SalaEncerrada 
} from './styles';

import {AccessTime, Today} from '@material-ui/icons';

const SalaItem = ({sala, salaLink}) => {

    const {isAdmin} = useAuth();

    function getHour(date){
        return date.split(" ")[1];
    }

    function getDate(date){
        return date.split(" ")[0];
    }

    let dt_inicio = getDate(sala.inicio);
    let hr_inicio = getHour(sala.inicio);
    let dt_termino = getDate(sala.termino);
    let hr_termino = getHour(sala.termino);

    let linkActive = false;
    let situacaoSala = <SalaAguardando>Aguardando</SalaAguardando>;

    switch(sala.situacao){
        case "aberta": {
            linkActive = true;
            situacaoSala = '';
            break;
        }
        case "encerrada": {
            situacaoSala = <SalaEncerrada>Encerrada</SalaEncerrada>;
            break;
        }
        default: break;
    }

    let salaLinkBtn;

    //se o material da sala for do tipo Stream
    if(sala.materialPrincipal && sala.materialPrincipal.tipo.id == 2){
        salaLinkBtn = <SalaLink href={sala.materialPrincipal.link_material} target="_blank" rel="noopener noreferrer">Acessar sala</SalaLink>
        //salaLinkBtn = <SalaLink href={`${salaLink}/${sala.id}`} target="_blank" rel="noopener noreferrer">Acessar sala</SalaLink>
    }else{
        salaLinkBtn = <SalaLink href={`${salaLink}/${sala.id}`}>Acessar sala</SalaLink>;
    }

    return (
        <Col xs="12" sm="6" md="4" className="mb-4">
            <Sala>
                <SalaHour>
                    <AccessTime className="pr-2"/> {`${hr_inicio} - ${hr_termino}`} 
                </SalaHour>
                <SalaBody>
                    <SalaDate><Today fontSize="small"/> {(dt_inicio !== dt_termino) ? `${dt_inicio} - ${dt_termino}` : dt_inicio}</SalaDate>
                    <SalaTitle>{sala.titulo}</SalaTitle>
                    <SalaFooter>
                        {(isAdmin || linkActive) && salaLinkBtn}
                        {situacaoSala}
                    </SalaFooter>
                </SalaBody>
            </Sala>
        </Col>
    );
}

export default SalaItem;
