import React, { useState, useEffect } from 'react';

import {Container} from 'reactstrap';

import VideoPlayer from './../VideoPlayer';

import SalaDetails from './../SalaDetails';
import SalaMateriais from './../SalaMateriais';

function SalaView({sala}) {

    const [materiais, setMateriais] = useState([]);

    useEffect(() => {
        
        setMateriais(sala.materiais ? sala.materiais : []);
        
    }, [sala]);


    const videos = materiais.filter((material, i) => {
        console.log(material);
        return material.tipo.id === 1 || material.tipo.id === 2;
    })

    const materiaisLinks = materiais.filter((material) => {
        return material.tipo.id === 3 || material.tipo.id === 4;
    });

    return (
        <Container className="mb-4">
            {videos.length
                ? <VideoPlayer videos={videos} />
                : null
            }
            
            <SalaDetails sala={sala} />

            <SalaMateriais materiais={materiaisLinks} />

        </Container>
    );
}

export default SalaView;