import React, { useEffect, useState } from 'react';

import {hourFromDate, periodFromDates} from './../../services/utils';

import { 
    Container,
    HeaderCollapse,
    IconCollapse,
    Content, 
    ContentTitulo, 
    ContentTituloHora
} from './styles';

import {
    Collapse
} from 'reactstrap';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

function SalaDetails({sala}) {

    const [isOpenCollapseDescricao, setIsOpenCollapseDescricao] = useState(false);
    const [hrInicio, setHrInicio] = useState('');
    const [hrTermino, setHrTermino] = useState('');
    const [data, setData] = useState('');

    const handleOpenCollapseDescricao = () => setIsOpenCollapseDescricao(!isOpenCollapseDescricao);

    useEffect(() => {

        setData(periodFromDates(sala.inicio, sala.termino));
        setHrInicio(hourFromDate(sala.inicio));
        setHrTermino(hourFromDate(sala.termino));

        if(sala.descricao.length < 3000){
            setIsOpenCollapseDescricao(true)
        }

    }, [sala]);

    return (
        <Container>
            <HeaderCollapse
                onClick={handleOpenCollapseDescricao}
            >
                <Content>
                    <ContentTitulo>
                        {sala.titulo}
                    </ContentTitulo>
                    <ContentTituloHora>{`${hrInicio} - ${hrTermino} | ${data}`}</ContentTituloHora>
                </Content>
                <IconCollapse>
                    {(isOpenCollapseDescricao)
                        ? <ExpandLess />
                        : <ExpandMore />
                    }
                </IconCollapse>
            </HeaderCollapse>
            <Collapse isOpen={isOpenCollapseDescricao}>
                <div dangerouslySetInnerHTML={{__html: sala.descricao}} />
            </Collapse>
        </Container>
    );
}

export default SalaDetails;